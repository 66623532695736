<template>
  <!-- 成员管理 -->
  <div class="TearmAdmin public-studioAdmin-body">
    <StudioAdminTabs :list="tabs" @change="tabsChange"></StudioAdminTabs>
    <!-- <div class="navC">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
        <el-tab-pane label="成员管理" name="1"></el-tab-pane>
        <el-tab-pane :label="'成员审核（' + StudioObj.status_user + '）'" name="2"></el-tab-pane>
      </el-tabs>
    </div> -->
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop public-form-studioAdmin">
        <el-input v-model="form.search_name" placeholder="请输入姓名/单位" class="input"></el-input>
        <el-select v-model="form.role_name" placeholder="请选择角色" style="width: 150px; margin-right: 10px;">
          <el-option v-for="(item, index) in roleSelect" :key="index" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <el-button type="primary" @click="TearmTab()">查询</el-button>
        <el-button @click="clearData">重置</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :stripe="true" class="public-table-studioAdmin">
          <el-table-column label="序号" align="center" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="成员姓名" align="center" width="120">
            <template slot-scope="scope">
              <div class="user-info">
                <img :src="scope.row.photo_url" class="user-cover">
                <p class="user-text">{{ scope.row.realname }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="roleStudio_name" label="角色" align="center" />
          <el-table-column prop="organization_name" label="单位" align="center" />
          <el-table-column prop="contribute_count" label="贡献值" align="center" width="70" />
          <el-table-column prop="create_time" label="加入时间" align="center" width="180" />
          <el-table-column prop="date" label="操作" align="center" width="140">
            <template slot-scope="scope">
              <template v-if="scope.row.role_studio_child_id == 5">/</template>
              <template v-else>
                <el-button :disabled="scope.row.role_studio_child_id == 5" type="text" class="text-color-blue"
                  @click="roleSelectWay(scope.row)">角色分配</el-button>
                <el-button :disabled="scope.row.role_studio_child_id == 5" type="text" class="text-color-red"
                  @click="isShowRemovie(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
      <!-- 弹框 -->
      <div class="TBox">
        <el-dialog title="角色分配" :visible.sync="TabVisible" @close="onClose">
          <div class="roleBox">
            <p class="tip">
              您正在为
              <span>{{ userInfo.realname }}</span>
              分配角色，请勾选角色进行分配{{ studio_id }}
            </p>
            <div class="Kbox" v-for="(item, index) in roleSelect" :key="index">
              <div class="cBox">
                <el-radio v-model="studio_id" :label="item.id">{{ item.name }}</el-radio>
                <!-- <el-checkbox v-model="item.isCheck">{{ item.name }}</el-checkbox> -->
              </div>
              <p>{{ item.dec }}</p>
            </div>
          </div>
          <div class="pl30 mb20" v-if="studio_id == 1">
            <el-radio-group v-model="radio">
              <el-radio v-for="item in roleList" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
          <div class="btn">
            <el-button class="one" @click="TabVisible = false">取消</el-button>
            <el-button type="primary" class="two" @click="AddRole()">保存</el-button>
          </div>
        </el-dialog>
      </div>

      <!-- 移除弹框 -->
      <div class="TBox">
        <el-dialog title="角色移除" :visible.sync="TabVisible1">
          <div class="roleBox1">
            <p class="tx1">
              <i class="iconfont icontishi"></i>
              你确认移除
              <span>{{ userInfo.realname }}</span>
              吗？
            </p>
            <p class="tx2">移除后，ta将收到相应的消息提醒。</p>
          </div>
          <div class="btn">
            <el-button class="one" @click="TabVisible1 = false">取消</el-button>
            <el-button type="primary" class="two" @click="deleteRole()">确认</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 弹框数据
      userInfo: "",
      //角色分配
      roleSelect: [
        {
          id: '2',
          isCheck: false,
          name: "骨干学员",
          dec: "骨干学员是工作室的重点培养对象，在四名的引领下提高个人教育教学能力。",
        },
        {
          id: '3',
          isCheck: false,
          name: "网络学员",
          dec: "网络学员是工作室的辐射对象，可以在线上参与工作室的研修。",
        },
        {
          id: '1',
          isCheck: false,
          name: "工作室管理员",
          dec: "工作室管理员具有管理工作室的权限，其管辖的权限可以在“角色管理”中设置。",
        },
      ],
      roleList: [
        { id: 1, name: "工作室助手", },
        { id: 2, name: "理论导师", },
        { id: 3, name: "指导顾问", },
        { id: 4, name: "教研员", },
      ],
      //弹框变量
      TabVisible: false,
      //弹框变量
      TabVisible1: false,
      input: "",
      tableData: [
      ],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_name: "",
        role_name: "",
        teaching_studio_id: "",
      },
      activeName: '1',
      radio: 1,
      studio_id: 3,
      StudioObj: {
        status_user: 0,
      },
      //导航
      tabs: [
        {
          label: "成员管理",
          count: 0
        },
        {
          label: "成员审核",
          count: 0
        }
      ],
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //初始化列表数据
    this._idnexTeachingStudio()
    this.TearmTab();
  },
  computed: {},
  methods: {
    //切换回调
    tabsChange(val) {
      if (val == 1) {
        this.$router.push({
          path: "/TearmAudit"
        })
      }
    },
    async _idnexTeachingStudio() {
      let res = await this.$axios.get(
        "index/TeachingStudio/idnexTeachingStudio",
        { params: { teaching_studio_id: localStorage.getItem("studioId") } }
      );
      this.StudioObj = res.data.data
      this.tabs[1].count = this.StudioObj.status_user;
    },
    //重置数据
    clearData() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_name = "";
      this.form.role_name = "";
      //初始化列表数据
      this.TearmTab();
    },
    onClose() {
      this.roleSelect.map((e) => {
        e.isCheck = false;
      });
    },
    isShowRemovie(item) {
      this.userInfo = item;
      this.TabVisible1 = true;
    },
    //移除成员
    deleteRole() {
      this.$axios
        .delete("index/teachingStudioUser/deleteTeachingStudioUser", {
          params: {
            id: this.userInfo.id,
            teaching_studio_id: localStorage.getItem("studioId"),
          },
        })
        .then(() => {
          this.$message.success("成员移除成功");
          this.TabVisible1 = false;
          this.TearmTab();
        });
    },
    //角色添加
    AddRole() {
      let obj = { id: this.userInfo.id, role_studio_id: [], role_studio_child_id: "" };
      this.roleSelect.forEach((item) => {
        //判断是否选中，选中就放进数组里
        obj.role_studio_id = [this.studio_id]
        // if (item.isCheck == true) {
        //   obj.role_studio_id.push(item.id);
        // }
      });
      obj.role_studio_child_id = this.radio
      //  console.log(obj,"手机开121");
      this.$axios
        .put("index/teachingStudioUser/roleTeachingStudioUser", obj)
        .then(() => {
          //关闭弹窗
          this.TabVisible = false;
          //重新加载列表数据
          this.TearmTab();
          this.$message.success("分配角色成功");
        });
    },

    //角色分配弹窗
    roleSelectWay(item) {
      this.TabVisible = true;
      this.userInfo = item;
      //  回显处理role_studio_id
      this.radio = item.role_studio_child_id || 1
      this.studio_id = item.role_studio_id || 3
      // let arr = item.role_studio_id.split(",");
      // arr.map((e) => {
      //   this.roleSelect.map((o) => {
      //     if (e == o.id) {
      //       o.isCheck = true;
      //     }
      //   });
      // });
    },
    async TearmTab() {
      this.form.teaching_studio_id = localStorage.getItem("studioId");
      let res = await this.$axios.get(
        "index/teachingStudioUser/listTeachingStudioUser",
        { params: this.form }
      );
      this.tableData = res.data.data.data || [];
      this.total = res.data.data.total || 0;
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.TearmTab();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.TearmTab();
    },
  },
};
</script>
<style lang="less" scoped>
.TearmAdmin {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    box-sizing: border-box;

    /deep/.el-tabs__header {
      margin: 0;

      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .el-tabs__item.is-active {
        font-weight: bold;
      }
    }
  }

  .contentBox {
    padding: 26px 26px;

    .selectTop {
      .input {
        width: 216px;
        margin-right: 10px;
      }
    }

    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    .TBox {
      /deep/.el-dialog__header {
        border-bottom: 1px solid #ebebeb;
        font-weight: bold;
      }

      .roleBox {
        .tip {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 30px;

          span {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
          }
        }

        .Kbox {
          /deep/.el-checkbox__label {
            font-size: 16px;
            color: #333333;
          }

          p {
            width: 520px;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #999999;
            margin-left: 27px;
            margin-bottom: 25px;
            margin-top: 12px;
          }
        }
      }

      .roleBox1 {
        width: 280px;
        margin: 0 auto;

        .tx1 {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 14px;

          i {
            color: #ff8201;
            font-size: 18px;
          }

          span {
            font-weight: 700;
          }
        }

        .tx2 {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 42px;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .one {
          width: 148px;
          height: 42px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 700;
          color: #333333;
        }

        .two {
          width: 148px;
          height: 42px;
          background: #ff8201;
          border-radius: 6px;
          border: 1px solid #ff8201;
          font-size: 16px;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;

  .user-cover {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
  }

  .user-text {
    font-size: 16px;
    color: #333333;
    flex: 1;
    overflow: hidden;
  }
}
</style>
